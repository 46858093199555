import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
  backgroundText: string;
}

const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;
  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 550px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h2`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 60px;
  text-transform: capitalize;
  color: #303030;
  @media screen and (max-width: 550px) {
    text-align: center;
    font-size: 22px;
  }
  @media screen and (min-width: 550px) {
    text-align: center;
  }
`;

const Underline = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  height: 5px;
  width: 150px;
`;

const BackgroundElement = styled.h1<{ isRight?: string }>`
  color: rgba(0, 0, 0, 0.03);
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 120px;
  position: absolute;
  left: 100px;
  margin-top: -60px;
  @media screen and (max-width: 550px) {
    left: 20px;
    font-size: 60px;
    margin-top: -40px;
  }
  @media screen and (min-width: 550px) {
    font-size: 60px;
  }
  ${(props) =>
    props.isRight === 'true' &&
    css`
      right: 100px !important;
      left: auto;
    `}
`;

const SectionTitle: React.FC<Props> = ({ children, backgroundText }) => {
  return (
    <Container>
      {backgroundText && <BackgroundElement
        isRight={
          backgroundText.includes('register') || backgroundText.includes('schedule')
            ? 'true'
            : 'false'
        }
      >
        {backgroundText}
      </BackgroundElement>}
      <Title>{children}</Title>
      <Underline />
    </Container>
  );
};

export default SectionTitle;
