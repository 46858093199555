import React from 'react';
import styled from 'styled-components';

interface Props {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 250px;
  @media screen and (max-width: 550px) {
    padding: 50px 30px;
  }
  @media screen and (min-width: 550px) {
    padding: 70px 80px;
  }
  @media screen and (min-width: 1100px) {
    padding: 100px 200px;
  }
`;

const FoldContainer: React.FC<Props> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default FoldContainer;
