import React from 'react';
import styled from 'styled-components';
import RightIcon from '../../assets/right_icon.svg';
import { Link } from 'gatsby';

interface Props {
  to: string;
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenuItemContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 50px;
  text-decoration: none;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #414042;
`;

const Text = styled.p``;

const MobileMenuItem: React.FC<Props> = ({ children, setShowMenu, to }) => {
  return (
    <MobileMenuItemContainer onClick={() => setShowMenu(false)} to={to}>
      <Text>{children}</Text>
      <img src={RightIcon} alt="Right icon" />
    </MobileMenuItemContainer>
  );
};

export default MobileMenuItem;
