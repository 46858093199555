// Core
import { DefaultTheme } from 'styled-components';

const styledComponentsTheme: DefaultTheme = {
  colors: {
    primary: '#F6B217',
  },
};

export default styledComponentsTheme;
