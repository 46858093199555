import React from 'react';
import styled, { css } from 'styled-components';
import topChevron from '../../assets/top_chevron.svg';
import { Link } from 'gatsby';

interface Props {}

const Container = styled.div<{ isRules?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 50px 150px;
  ${(props) => {
    return (
      props.isRules === 'true' &&
      css`
        @media screen and (min-width: 1100px) {
          position: fixed;
          bottom: 0;
          z-index: 999;
          width: 100%;
        }
        @media screen and (min-width: 550px) {
          position: fixed;
          bottom: 0;
          z-index: 999;
          width: 100%;
        }
      `
    );
  }}
  @media screen and (max-width: 550px) {
    flex-direction: column;
    padding: 20px 40px;
  }
  @media screen and (min-width: 550px) {
    padding: 30px 50px;
  }
`;

const Title = styled(Link)`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  color: #303030;
  text-decoration: none !important;
  cursor: pointer;
  b {
    font-weight: bolder;
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 20px;
  }
  &:hover {
    color: #303030;
  }
`;

const ChevronContainer = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #414042;
  height: 50px;
  width: 50px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 999;
  cursor: pointer;
  @media screen and (max-width: 550px) {
    bottom: 20px;
    right: 20px;
  }
`;

const FooterOption = styled(Link)`
  color: #414042;
  text-decoration: none !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  &:hover {
    color: #303030;
  }
`;

const Footer: React.FC<Props> = () => {
  const isBrowser = () => typeof window !== 'undefined';

  return (
    <Container
      isRules={isBrowser() && window.location.pathname.includes('rules') ? 'true' : 'false'}
    >
      <Title to="/#">
        <b>HF</b>Leadership
      </Title>
      <FooterOption to="/rules">Rules of participation</FooterOption>
      {isBrowser() && window.location.pathname.includes('rules') ? null : (
        <ChevronContainer to="/#">
          <img src={topChevron} alt="top chevron" />
        </ChevronContainer>
      )}
    </Container>
  );
};

export default Footer;
