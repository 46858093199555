const mobileMenuData = [
  {
    to: '/#about',
    text: 'About',
  },
  {
    to: '/#schedule',
    text: 'Schedule',
  },
  {
    to: '/#register',
    text: 'Register Now',
  },
];

export default mobileMenuData;
