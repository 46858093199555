import React from 'react';
import { useLocation } from '@reach/router';
import GlobalStyles from '../../Styles/globalStyles';
import { ThemeProvider } from 'styled-components';
import theme from '../../Styles/theme';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SEO from '../seo';

interface Props {}

const Layout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <SEO
        title="HF Leadership"
        description="Leadership Pearls for Established & Emerging Heart Failure Programs"
        pathname={location.pathname}
        location={location}
      />
      <GlobalStyles />
      <Header />
      <div>{children}</div>
      <Footer />
    </ThemeProvider>
  );
};

export default Layout;
