import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import CustomButton from '../common/CustomButton';
import HamburgerMenu from '../../assets/hamburger_icon.svg';
import CloseIcon from '../../assets/close_icon.svg';
import MobileMenuItem from '../common/MobileMenuItem';
import mobileMenuData from '../../data/mobileMenuData';

interface Props {}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 9999;
  @media screen and (max-width: 550px) {
    width: 100%;
    flex-direction: column;
  }
  @media screen and (min-width: 550px) {
    flex-direction: column;
  }
`;

const ContainerInner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.primary};
  padding: 20px 150px;
  width: 100%;
  @media screen and (max-width: 550px) {
    width: 100%;
    padding: 10px 30px;
  }
  @media screen and (min-width: 550px) {
    align-items: center;
    padding: 20px;
  }
`;

const Title = styled(Link)`
  font-family: Work Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 38px;
  color: #303030;
  cursor: pointer;
  text-decoration: none !important;
  b {
    font-weight: bolder;
  }
  &:hover {
    color: #303030;
  }
`;

const Options = styled.div`
  margin: 0;
  @media screen and (max-width: 550px) {
    display: none;
  }
  @media screen and (min-width: 550px) {
    display: none;
  }
  @media screen and (min-width: 1100px) {
    display: block;
  }
`;

const OptionsLink = styled(Link)`
  text-decoration: none !important;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #303030;
  margin: 0 30px 0 50px;
  &:hover {
    color: #303030;
  }
`;

const HamburgerMenuContainer = styled.img`
  @media screen and (min-width: 550px) {
    display: none;
  }
  @media screen and (min-width: 550px) {
    display: block;
  }
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  background-color: white;
  border-bottom: 1px solid #eee;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

const CloseIconContainer = styled.img`
  @media screen and (min-width: 1100px) {
    display: none;
  }
  @media screen and (min-width: 550px) {
    display: block;
  }
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;

const Header: React.FC<Props> = () => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container>
      <ContainerInner>
        <Title to="/">
          <b>HF</b>Leadership
        </Title>
        <Options>
          <OptionsLink to="/#about">About</OptionsLink>
          <OptionsLink to="/#schedule">Schedule</OptionsLink>
          <OptionsLink to="/#register">
            <CustomButton>Register Now</CustomButton>
          </OptionsLink>
        </Options>
        {!showMenu ? (
          <HamburgerMenuContainer onClick={() => setShowMenu(!showMenu)} src={HamburgerMenu} />
        ) : (
          <CloseIconContainer onClick={() => setShowMenu(!showMenu)} src={CloseIcon} />
        )}
      </ContainerInner>
      <MobileMenu>
        {showMenu && (
          <>
            {mobileMenuData.map((el) => (
              <MobileMenuItem setShowMenu={setShowMenu} key={el.text} to={el.to}>
                {el.text}
              </MobileMenuItem>
            ))}
          </>
        )}
      </MobileMenu>
    </Container>
  );
};

export default Header;
