import React from 'react';
import styled, { css } from 'styled-components';

export type ButtonComponentProps = {
  children?: HTMLCollection | string;
  onClick?: (e?: React.MouseEvent) => void;
  buttonType?: 'primary' | 'secondary';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled.button<ButtonComponentProps>`
  outline: none;
  color: white;
  background-color: #303030;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  // margin-left: 50px;
  font-size: 16px !important;
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  @media screen and (max-width: 550px) {
    width: 100%;
    margin-left: 0;
  }

  ${(props) =>
    props.buttonType === 'primary' &&
    css`
      color: white;
      background-color: ${props.theme.colors.primary};
    `}
  &:disabled {
    background-color: #ececec;
    color: #696969;
    cursor: not-allowed;
  }
  &:focus {
    outline: none;
  }
`;

const CustomButton: React.FC<ButtonComponentProps> = ({
  onClick,
  children,
  buttonType,
  ...otherProps
}) => {
  return (
    <Button buttonType={buttonType} onClick={onClick} {...otherProps}>
      {children}
    </Button>
  );
};

export default CustomButton;
